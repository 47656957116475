export default class BasePlugin {
  context: any;
  model: any;
  card: any;
  constructor (context) {
    this.context = context;
    this.model = this.context.getModel();
    this.card = this.context.getCard;
  }
}
